import React from 'react';
import {Link} from 'react-router-dom';

import {Navbar_Link_Data} from './Data/LinkData.js';
import {isOpen} from './Navbar.js';

export const Dropdown = () =>
{
  
  return (
    <>
      <section className="dropdown-menu" >
        <ul className="dropdown-menu-links">
          {
            Navbar_Link_Data.map((link, index) =>
            {
              return <li key={index}>
                      <Link className='dropdown-menu-link' to={link.path} onClick={() => isOpen.value = !isOpen.value}>{link.pathName}</Link>
                    </li>
            })
          }
        </ul>
      </section>
    </>
  )
}