import React from 'react';


// Components
import {Navbar} from './../Navbar/Navbar.js';

// Data
import { ART_PAGE_DATA} from './Data/ArtPageData.js';



export const ArtPage = () =>
{
  return (
    <>
      <Navbar/>

      <section className="art-page">
        <header className="art-page-header">
          <div className="intro-container">
            <span className="homepage-title">The Artist We Love</span>
            <p className="homepage-intro">
                The artist that make up our collection.
            </p>
          </div>
        </header>

        <main className="art-page-main">
          {
            ART_PAGE_DATA.map((artist, index) =>
            {
              return <article className="artist-article" key={index}>
                       <span className='artist-article-name'>{artist.name}</span>
                       <div className="artist-img-container">
                        {
                          artist.art.map((work, i) =>
                          {
                            return <div className="artist-card" key={i}>
                              <img src={`./Photos/Art_Page/${work.img}`} alt="" />
                              <span className='artwork-size-span'>Size: {work.size}</span>
                              <span className="artwork-status-span">{work.status}</span>
                            </div>
                          })
                        }
                       </div>
                     </article>
            })
          }
        </main>
      </section>
    </>
  )
}