import React from 'react';

// Components
import {Navbar} from './../Navbar/Navbar.js';
import {Footer} from './../Navbar/Footer.js';

// Data
import {images} from './Data/ImagesData.js';
import {HOME_INFO} from './Data/HomeInfoData.js';


export const HomePage = () =>
{

   return (
      <>
         <Navbar/>

         <section className='homepage'>
            <header className="homepage-header">
               <div className="intro-container">
                  <span className="homepage-title">For Art and The World We Live In </span>
                  <p className="homepage-intro">
                     Helping bring art into your company and life
                  </p>
               </div>
            </header>

            <h1 className='homepage-section-title'>Rent Art</h1>

            <div className="homepage-main">
               <title>Art Rental</title>

               <div className="homepage-img-container">
                  {
                     images.map((img, index) =>
                     {
                        return <img className='homepage-img' src={`/Photos/Home/${img.url}`}  alt={`/Photos/Home/${img.url}.jpg`} key={index}/>
                     })
                  }
               </div>
            </div>


            <div className="homepage-info-section">
               {
                  HOME_INFO.map((data, index) =>
                  {
                     return   <article className="home-info-article" key={index}>
                                 <span className="home-info-title">{data.title}</span>
                                 <p className="home-info-desc">{data.desc}</p>
                              </article>
                  })
               }
            </div>
         </section>
          
         
         <Footer/>
      </>
      )
}