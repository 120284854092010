import React from 'react';
import {Link} from 'react-router-dom';
import {signal, effect} from '@preact/signals-react';

import {Navbar_Link_Data} from './Data/LinkData';
import {Dropdown} from './Dropdown.js';
import {FaBars} from 'react-icons/fa'


// Boolean to toggle dropdown menu
export const isOpen = signal(false);
export const dropdownRef = signal(null);


export const Navbar = () =>
{
   effect( () =>
   {
      if(isOpen.value)
      {
         dropdownRef.value = document.getElementsByClassName('dropdown-menu');
         const height = dropdownRef.value[0].getBoundingClientRect().height;

         if(height < 10)
         {
            dropdownRef.value[0].style.height = `125px`;
            dropdownRef.value[0].style.borderBottom = 'solid black 2px'
         }
      }

      else if(!isOpen.value && dropdownRef.value !== null)
      {
         dropdownRef.value[0].style.height = `0px`;
         dropdownRef.value[0].style.border = 'none'
      }
      
   })

   return (
      <>
         <nav className='navbar'>
            <Link className="navbar-logo" to='/'>
               <span>Cortez</span>
               <span>Art</span>
               <span>Collection</span>
            </Link>      

            {/* Dynamically Creat all the navbar links */}
            <div className="navbar-center">
               <ul className='navbar-link-list'>
                  {
                     Navbar_Link_Data.map((link, index) =>
                     {
                        return <li key={index}><Link className='navbar-link' to={link.path}>{link.pathName}</Link></li>
                     })
                  }
               </ul>      
            </div>

            <button className="navbar-toggle-btn" onClick={() => {isOpen.value = !isOpen.value}}>
               <FaBars/>
            </button>

            <Dropdown isOpen={isOpen}/>
         </nav>

         
      </>
      )
}