import React from 'react';
// import {signal} from '@preact/signals-react';

import {Navbar} from '../Navbar/Navbar.js';

// const name = signal("");
// const subject = signal("");
// const message = signal("");

export const ContactPage = () =>
{
  const handleSubmit = (e) =>
  {
    // e.preventDefault();
    console.log(e.target);

    const formData = new FormData(e.currentTarget);
    const name = formData.get("name");

    console.log(`Name: ${name}`);
    console.log(formData.get('email'));
  }

  return (
    <>
      <Navbar/>

      <section className="contact-page">
        <main className="contact-page-main">
          <form className="contact-form" onSubmit={handleSubmit} action="https://formsubmit.co/cortezartcollection@gmail.com" method="POST">
            {/* <label className="form-label">Name</label> */}
            <input type="text" className="form-input" name='name' required={true} placeholder='Name'/>
            {/* <label className="form-label">subject</label> */}
            <input type="text" className="form-input" name='Subject' required={true} placeholder='Subject'/>
            {/* <lable className="form-label">email</lable> */}
            <input type="email" className="form-input" name='email' required={true} placeholder='Email'/>
            {/* <label className="form-label">Message</label> */}
            <textarea className="form-message" name='message' required={true} placeholder='Send us a message'  rows="10"/>
            <button className="form-submit-btn">Send</button>
            <input type="text" name="_honey" style={{display:'none'}}></input>
            <input type="hidden" name="_next" value="https://cortezartcollection.com/"></input>
          </form>
        </main>
      </section>
    </>
  )
}