import './App.css';
import {Routes, Route} from 'react-router-dom';

import {HomePage} from './Home/HomePage';
import {ArtPage} from './Art/ArtPage.js';
import {AboutPage} from './About/About.js';
import {ContactPage} from './Contact/ContactPage.js';

function App() 
{
  return (
    <Routes>
      <Route path='/' location='/' element={<HomePage/>}/>
      <Route path='/Art'  element={<ArtPage/>}/>
      <Route path='/About' element={<AboutPage/>}/>
      <Route path='/Contact' element={<ContactPage/>}/>
    </Routes>
      
  );
}

export default App;
