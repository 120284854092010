import React from 'react';

import {Navbar} from './../Navbar/Navbar.js';


export const AboutPage = () =>
{
  return (
    <>
      <Navbar/>
      <section className="about-page">
        <header className="about-header">
          <div className="intro-container">
            <span className="homepage-title">About Us</span>
            <p className="homepage-intro">
                The artist that make up our collection.
            </p>
          </div>
        </header>

        <main className="about-main">
          <p className='about-desc'>There is a great amount of joy that comes from surrounding oneself with art and sharing it with others. This our attempt to do so. 
              <br/> <br/>pm Born in the Bay Area, inspired by trips across California, and motivated by art across Europe. Art is an expensive endeavor and art rental is our way making it more affordable to access.</p>
          <div className='about-main-img' id="about-img-1" >
          </div>
          <div className="about-main-img" id="about-img-2" >
          </div>
        </main>
      </section>
    </>
    )
}