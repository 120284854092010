
export const ART_PAGE_DATA = 
[
  {
    name: `Steve Thomas`,
    art: 
    [
      {img: 'SteveThomas/MountTamalpais.jpg', size: '24x36', status: 'Available',},
      {img: 'SteveThomas/GoldenGateBridge.jpg', size: '24x36', status: 'Available',},
      {img: 'SteveThomas/MuirWoods.jpg', size: '24x36', status: 'Available',},
      {img: 'SteveThomas/CityByTheBay.jpg', size: '24x36', status: 'Available',},
      {img: 'SteveThomas/SanFrancisco.jpg', size: '24x36', status: 'Available',},
      {img: 'SteveThomas/CptAmerica_small.jpg', size: '14x20', status: 'Available',},
      {img: 'SteveThomas/IronMan_small.jpg', size: '14x20', status: 'Available',},
      {img: 'SteveThomas/Thor_small.jpg', size: '14x20', status: 'Available',},
    ]
  },

  {
    name: `GGNRA`,
    art: 
    [
      {img: 'GGNRA/MuirWoods_small.jpg', size: '23x30', status: 'Available',},
      {img: 'GGNRA/FortPoint_small.jpg', size: '23x30', status: 'Available',},
      {img: 'GGNRA/Alcatraz_small.jpg', size: '23x30', status: 'Available',},
      {img: 'GGNRA/GGBridge_small.jpg', size: '23x30', status: 'Available',},
      {img: 'GGNRA/OceanBeach_small.jpg', size: '23x30', status: 'Available',},
      {img: 'GGNRA/PointBonita_small.jpg', size: '23x30', status: 'Available',},
      {img: 'GGNRA/StinsonBeach_small.jpg', size: '23x30', status: 'Available',},
      {img: 'GGNRA/MarinHeadlands_small.jpg', size: '23x30', status: 'Available',},
      {img: 'GGNRA/ThePresidio_small.jpg', size: '23x30', status: 'Available',},
      {img: 'GGNRA/CrissyField_small.jpg', size: '23x30', status: 'Available',},
      {img: 'GGNRA/MtTam_small.jpg', size: '23x30', status: 'Available',},
      {img: 'GGNRA/LandsEnd_small.jpg', size: '23x30', status: 'Available',},

    ]
  },
]