
export const images = 
[
  {url: `MountTamalpais.jpg`, title: 'Mt. Tam Mountain Biking', artist: 'Steve Thomas',},
  {url: `MuirWoods_GGNRA.jpg`, title: 'Muir Woods Poster', artist: 'GGNRA',},
  {url: `GoldenGateBridge.jpg`, title: 'Golden Gate Bridge', artist: 'Steve Thomas',},
  {url: `FortBaker_GGNRA.jpg`, title: 'Fort Baker Poster', artist: 'GGNRA',},
  {url: `MuirWoods.jpg`, title: 'Muir Woods', artist: 'Steve Thomas',},
  {url: `Alcatraz_GGNRA.jpg`, title: 'Alcatraz Poster', artist: 'GGNRA',},
  {url: `CityByTheBay.jpg`, title: 'City by the Bay', artist: 'Steve Thomas',},
  {url: `MarinHeadlands_GGNRA.jpg`, title: 'Marin Headlands Poster', artist: 'GGNRA',},
];