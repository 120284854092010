import React from 'react';

// import {Links} from 'react-router-dom';


export const Footer = () =>
{
  return (
    <>
      <footer className='footer'>

      </footer>
    </>
  )
}